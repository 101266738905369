import { Snackbar, useTheme } from "@mui/material";

export interface SnackbarMessage {
  text: string;
  variant: "default" | "success" | "error";
}

interface DtwSnackbarProps {
  message: SnackbarMessage | null | undefined;
  onClose: () => unknown;
}

export const DtwSnackbar = ({ message, onClose }: DtwSnackbarProps) => {
  const theme = useTheme();
  let backgroundColor: string | undefined = undefined;
  if (message?.variant === "error") {
    backgroundColor = theme.palette.error.main;
  } else if (message?.variant === "success") {
    backgroundColor = theme.palette.success.main;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={3000}
      open={!!message}
      onClose={onClose}
      message={message?.text}
      key="snack"
      ContentProps={{
        sx: {
          background: backgroundColor,
        },
      }}
    />
  );
};
